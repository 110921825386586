import React from 'react';
import { AgendaProRebranding } from '@agendapro/emerald-icons';
import { Text } from '@agendapro/emerald/v2';
import { useTranslation } from 'react-i18next';
import * as St from './PoweredBy.styles';

const PoweredBy = () => {
  const { t } = useTranslation();

  return (
    <St.Wrapper>
      <Text type="p-xsmall">{t('POWERED_BY')}</Text>
      <AgendaProRebranding size={12} />
    </St.Wrapper>
  );
};

export default PoweredBy;
