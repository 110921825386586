import styled, { css } from 'styled-components';

import Image from 'next/image';
import { devices } from '@/UI/Styles';
import { applyFlexAndCenter } from '@/styles';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.migrateColors('background.tertiary', 'background')};
`;

export const MainHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.07), 0px 0px 6.175px rgba(0, 0, 0, 0.035);
  padding: 7px 43px;
  border-radius: 0px 0px 12px 12px;
  margin-bottom: ${({ theme }) => theme.spacing[28]};
  color: ${({ theme }) => theme.migrateColors('text.default', 'gray50')};
  gap: ${({ theme }) => theme.spacing[12]};
`;

export const Close = styled.button`
  ${applyFlexAndCenter}
  margin-left: auto;
  margin-right: 0px;
  path {
    fill: ${({ theme }) => theme.migrateColors('icon.default', 'gray')};
  }
`;

export const MobileHeader = styled.div`
  ${({ theme }) => css`
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${theme.spacing[16]};
    background-color: ${theme.migrateColors('background.primary', 'white')};
    border-radius: 8px 8px 0 0;
    margin: 0 auto;
    box-shadow: 0 6px 8px 0 rgba(57, 65, 73, 0.08);
    @media ${devices['no-mobile']} {
      max-width: 500px;
    }
    @media ${devices.Medium} {
      width: 90%;
      max-width: 717px;
    }
    @media ${devices.Large} {
      width: 100%;
      min-width: 650px;
    }
    @media ${devices.XLarge} {
      max-width: 830px;
      margin: 0;
      min-height: 600px;
    }
  `}

  .previousButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.migrateColors('text.btnLink', 'gray80')};
    margin-left: ${({ theme }) => theme.spacing[16]};
    text-decoration: none;

    &:active,
    &:focus {
      background-image: none;
    }
  }
`;

export const Logo = styled(Image)`
  border-radius: 80%;
  margin-right: 17px;
`;
