import { useRouter } from 'next/router';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useReservation } from '@/context/ReservationsContext';
import usePageContext from '../usePageContext';
import { useShowComponent } from '../showComponent';
import {
  axiosErrorHandler,
  filterEmptyBookings,
  formatCustomAttributes,
  getAccessTokenAndId,
  getBookings,
  getServicesTotal,
} from '@/utils';
import { BookingServicesProps } from '@/components/ReservationModal/ModalPlans/types';
import { ENV } from '@/config/env';
import { useDialogsContext } from '@/context/DialogsContext';
import { useBookReservation, useCheckBookingPlans } from '@/services/bookings';
import { useIframeRouter } from '../useIframeRouter';
import { Gtag } from '@/lib/gtag';
import { FbPixel } from '@/lib/fpixel';

export const useDoReservation = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { companyOverview, isSalfa, currentLocation, creativeSource } = usePageContext();
  const { routerReplace } = useIframeRouter();
  const { reservations, dispatch } = useReservation();
  const {
    companyBookingInfo,
    servicesToReserve,
    isSession,
    clientValidation,
    client,
    bookingMode,
    servicesWithProviders,
  } = reservations;
  const { presetNotes, policyTermActive } = companyBookingInfo;
  const checkBookingPlansMutation = useCheckBookingPlans();
  const bookMutation = useBookReservation();
  const bookMutationLoading = bookMutation.isLoading;
  const [actionType, setActionType] = useState('');
  const [availableMembership, setAvailableMembership] = useState<any[]>();
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [noneIncluded, setNoneIncluded] = useState(false);
  const [isAllIncludedInPlan, setIsAllIncludedInPlan] = useState(false);
  const { countryCode, locationId } = router.query as { countryCode: string; locationId: string };
  const { isShown: isModalPlansShown, hide: hideModalPlans, show: showModalPlans } = useShowComponent();
  const {
    isShown: isUnavailableBookingModalShown,
    hide: hideUnavailableBookingModal,
    show: showUnavailableBookingModal,
  } = useShowComponent();
  const [bookingServices, setBookingServices] = useState<BookingServicesProps>();
  const locationIdInt = parseInt(locationId, 10);
  const { setErrorDialog } = useDialogsContext();
  const bookings = getBookings(servicesToReserve, servicesWithProviders, bookingMode);
  const clientAndCustomAttributes = formatCustomAttributes(client);
  const formattedClient = {
    ...clientAndCustomAttributes,
    notes: `${presetNotes ? `${t('CLIENT_PAYLOAD.IMPORTANT')} ${presetNotes} \n \n ` : ''}${
      client.notes ? `${t('CLIENT_PAYLOAD.YOUR')} ${client.notes}` : ''
    }`,
  };

  const mustBePaidOnline = servicesToReserve[0].bundled
    ? servicesToReserve[0].bundle.some((service) => service.mustBePaidOnline)
    : servicesToReserve.some((service) => service.mustBePaidOnline);
  let canBePaidOnline = servicesToReserve[0].bundled
    ? servicesToReserve[0].bundle.some((service) => service.onlinePayable)
    : servicesToReserve.some((service) => service.onlinePayable);

  if (
    companyOverview?.allowsOnlinePayment === false ||
    companyOverview?.onlinePaymentCapable === false ||
    companyOverview?.providerOnlinePayment === null
  ) {
    canBePaidOnline = false;
  }

  const checkDisabled = () => {
    const { isValid, acceptedTerms } = clientValidation;

    if (checkBookingPlansMutation.isLoading) {
      return true;
    }
    if (bookMutation.isLoading) {
      return true;
    }
    if ((isValid && (acceptedTerms || !policyTermActive)) || companyBookingInfo.clientExclusive) {
      return false;
    }
    return true;
  };

  const getPagoFacilField = (paymentProvider: string | undefined): string => {
    if (paymentProvider === 'epayco') {
      return 'epayco';
    }
    if (paymentProvider === 'dlocal') {
      return 'dlocal';
    }
    if (paymentProvider === 'pago_facil') {
      return '2';
    }
    return '';
  };

  const makeReservation = (paymentProvider?: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const rwgToken = urlParams.get('rwg_token') || undefined;
    const createSourcePayload = rwgToken ? 'google_appointments' : creativeSource;

    bookMutation.mutate(
      {
        locationId: locationIdInt,
        payment: null,
        hasSessions: isSession,
        trxId: '',
        paymentPagoFacil: getPagoFacilField(paymentProvider),
        ...(paymentProvider === 'dlocal' ? { paymentDLocal: 'dlocal' } : { paymentDLocal: '' }),
        bookings: isSalfa
          ? {
              bookings: bookings.map((booking) => ({
                ...booking,
                paymentMembershipId: availableMembership?.find(
                  (membership) => membership.serviceId === booking.serviceId,
                )?.paymentMembershipId,
                recordHistory: {
                  ...reservations.recordHistory,
                  currentMileage: reservations.recordHistory?.body.currentMileage,
                },
                entity: reservations.entity,
              })),
            }
          : {
              bookings: bookings.map((booking) => ({
                ...booking,
                paymentMembershipId: availableMembership?.find(
                  (membership) => membership.serviceId === booking.serviceId,
                )?.paymentMembershipId,
              })),
            },
        client: formattedClient,
        creativeSource: createSourcePayload,
        rwgToken,
      },
      {
        onSuccess: (data) => {
          setIsBookingLoading(false);
          if (paymentProvider && !isAllIncludedInPlan) {
            window.location.href = `${ENV.BACK_URL}/propay/pay/?p=${paymentProvider}&b=${data.booking?.trxId}`;
          } else {
            const { bookingId, accessToken } = getAccessTokenAndId(data.redirectTo);

            routerReplace(`/${countryCode}/bookings/${bookingId}/${accessToken}/false`);
          }
        },
        onError: (data) => {
          setErrorDialog({ open: true, errorDetail: axiosErrorHandler(data) });
          dispatch({ type: 'SET_STEP', payload: 'dateSelection' });
          dispatch({ type: 'SET_HOUR_SELECTED', payload: null });
          setActionType('');
          setIsBookingLoading(false);
        },
      },
    );
  };

  const handleBookingServices = async (actionType: string) => {
    setIsBookingLoading(true);
    const items = servicesToReserve.map((service) => ({
      id: service.id,
      item_name: service.name,
      price: service.price,
      quantity: 1,
    }));
    const value = getServicesTotal(servicesToReserve[0]?.bundled ? servicesToReserve[0].bundle : servicesToReserve);

    Gtag.event({
      action: actionType,
      options: { currency: companyOverview.unit, value, item_list: items.map((i) => i.item_name) },
    });
    FbPixel.event(actionType, { contents: items, value, currency: companyOverview.unit });
    checkBookingPlansMutation.mutate(
      { locationId: locationIdInt, bookings, client: formattedClient },
      {
        onSuccess: (data) => {
          setIsBookingLoading(false);
          const allIncludedInPlan = data.bookingsData?.available.length === data.bookingsMembership?.available.length;

          setAvailableMembership(data.bookingsMembership?.available);
          setIsAllIncludedInPlan(allIncludedInPlan);
          const isEmptyBookingsMembership = filterEmptyBookings(data.bookingsMembership);
          const isNoneIncluded =
            !data.bookingsMembership.unavailable.length &&
            !data.bookingsMembership.available.length &&
            !data.bookingsData.unavailable.length;

          setNoneIncluded(isNoneIncluded);
          if (isNoneIncluded) {
            if (actionType === 'payAndReserve') {
              makeReservation(companyOverview?.providerOnlinePayment);
            }
            if (actionType === 'reserve') {
              makeReservation();
            }

            return;
          }

          if (data.bookingsData.unavailable.length) {
            setBookingServices(data);
            showUnavailableBookingModal();
          }

          if ((actionType === 'reserve' || actionType === 'payAndReserve') && !isEmptyBookingsMembership) {
            setBookingServices(data);
            showModalPlans();
          }
        },
        onError: (data) => {
          const attributesError: string | undefined = data?.response?.data?.error?.attributes
            .map((error) => error.message)
            .join('');

          if (attributesError) {
            setErrorDialog({
              open: true,
              errorDetail: attributesError,
              locationPhone: currentLocation?.phone,
            });
          } else if (data?.response?.data?.errors) {
            setErrorDialog({
              open: true,
              errorDetail: data.response.data.errors,
              locationPhone: currentLocation?.phone,
            });
          } else {
            setErrorDialog({
              open: true,
            });
            dispatch({ type: 'SET_STEP', payload: 'dateSelection' });
            dispatch({ type: 'SET_HOUR_SELECTED', payload: null });
          }
          setActionType('');
          setIsBookingLoading(false);
        },
      },
    );
  };

  const handleActionTaken = (buttonClicked: string) => {
    setActionType(buttonClicked);
    return handleBookingServices(buttonClicked);
  };

  const handleModalPlans = async () => {
    setIsBookingLoading(true);
    const servicesInMembership = servicesToReserve.filter((service) => {
      const serviceIncluded = availableMembership?.find((item) => item.serviceId === service.id);

      return !!serviceIncluded;
    });

    const canBePaidOnline = servicesToReserve.some(({ onlinePayable }) => onlinePayable);

    if (actionType === 'reserve' || servicesInMembership.length === servicesToReserve.length || !canBePaidOnline) {
      return makeReservation();
    }
    if (actionType === 'payAndReserve') {
      makeReservation(companyOverview?.providerOnlinePayment);
      return hideModalPlans();
    }
    return undefined;
  };

  return {
    actionType,
    bookingServices,
    canBePaidOnline,
    checkDisabled,
    companyOverview,
    handleActionTaken,
    handleBookingServices,
    handleModalPlans,
    hideModalPlans,
    isBookingLoading,
    bookMutationLoading,
    isModalPlansShown,
    mustBePaidOnline,
    noneIncluded,
    isUnavailableBookingModalShown,
    hideUnavailableBookingModal,
  };
};
