import { Step } from '@/context/ReservationsContext/ReservationContext.types';

import { NextStepMobileType, NextStepDesktopType } from './types';

export const handleNextStepDesktop = ({
  reservations,
  runDispatch = false,
  isSalfa,
  dispatch,
}: NextStepDesktopType): Step | null => {
  const {
    servicesToReserve,
    step,
    bookingMode,
    serviceBeingReserved,
    currentIndex,
    canChooseProvider,
    isSession,
    singleProvider,
  } = reservations;

  const uncompletedServiceIndex = servicesToReserve.length
    ? servicesToReserve?.findIndex(
        (service) => !service.hourSelected || (!service.providerId && service.providerId !== 0),
      )
    : null;

  const isProviderPage = window.location.pathname.includes('profesional');

  const shouldReturnClientForm = (
    bookingMode,
    serviceBeingReserved,
    isSession,
    singleProvider,
    servicesToReserve,
    canChooseProvider,
    currentIndex,
    uncompletedServiceIndex,
    isProviderPage,
  ) => {
    const isSingleServiceToReserve = servicesToReserve && servicesToReserve.length === 1;
    const isProviderChosen = servicesToReserve && servicesToReserve[currentIndex]?.serviceProvider;

    const isConsecutiveBooking = bookingMode === 'consecutive';
    const isBundledService = serviceBeingReserved?.bundled;
    const isSingleProviderSession = isSession;
    const isSingleProviderSingleService = singleProvider && isSingleServiceToReserve;
    const cantChooseSingleService = canChooseProvider === 'CANT' && isSingleServiceToReserve;
    const cantChooseProviderCompleted =
      (canChooseProvider === 'CANT' || isProviderChosen) && uncompletedServiceIndex === -1;
    const providerPageCompleted = isProviderPage && uncompletedServiceIndex === -1;
    const separateCantChooseCompleted =
      bookingMode === 'separate' && canChooseProvider === 'CANT' && uncompletedServiceIndex === -1;

    return isConsecutiveBooking ||
      isBundledService ||
      isSingleProviderSession ||
      isSingleProviderSingleService ||
      cantChooseSingleService ||
      cantChooseProviderCompleted ||
      providerPageCompleted ||
      separateCantChooseCompleted
      ? 'clientForm'
      : null;
  };

  const setServiceBeingReserved = (index?: number) => {
    if (runDispatch && dispatch && uncompletedServiceIndex !== null) {
      dispatch({
        type: 'SET_SERVICE_BEING_RESERVED',
        payload: servicesToReserve[index || index === 0 ? index : uncompletedServiceIndex],
      });
    }
  };

  if (isSalfa || (uncompletedServiceIndex === -1 && step !== 'clientForm')) {
    return 'clientForm';
  }

  if (step === 'modeSelection') {
    if (bookingMode === 'separate' && !serviceBeingReserved) {
      setServiceBeingReserved();
      return 'dateSelection';
    }
    if (servicesToReserve && servicesToReserve[currentIndex]?.serviceProviders.length === 1) {
      return 'dateSelection';
    }
    return 'providerSelection';
  }

  if (step === 'serviceSelection' && !serviceBeingReserved) {
    setServiceBeingReserved();
    return 'dateSelection';
  }

  if (step === 'dateSelection') {
    if (
      shouldReturnClientForm(
        bookingMode,
        serviceBeingReserved,
        isSession,
        singleProvider,
        servicesToReserve,
        canChooseProvider,
        currentIndex,
        uncompletedServiceIndex,
        isProviderPage,
      )
    ) {
      return 'clientForm';
    }
    if (
      bookingMode === 'separate' &&
      (canChooseProvider === 'CANT' || singleProvider) &&
      uncompletedServiceIndex !== -1
    ) {
      const index =
        servicesToReserve.length - 1 === uncompletedServiceIndex
          ? uncompletedServiceIndex
          : Number(uncompletedServiceIndex) + 1;

      setServiceBeingReserved(index);
      if (servicesToReserve.length - 1 === uncompletedServiceIndex) {
        return 'clientForm';
      }
      return 'dateSelection';
    }
    return 'providerSelection';
  }

  if (step === 'providerSelection') {
    if (bookingMode === 'consecutive') {
      return 'dateSelection';
    }
    if (bookingMode === 'separate' && uncompletedServiceIndex !== -1) {
      setServiceBeingReserved();
      return 'dateSelection';
    }

    if (isSession) {
      return 'dateSelection';
    }

    if (serviceBeingReserved?.bundled) {
      return 'dateSelection';
    }
    return 'clientForm';
  }

  if (step === 'clientForm') {
    return 'reservationSummary';
  }

  return null;
};

export const handleNextStepMobile = ({ reservations, isSalfa, dispatch }: NextStepMobileType) => {
  const {
    servicesToReserve,
    isEditing,
    providerPreSelected,
    isSession,
    bookingMode,
    canChooseProvider,
    companyBookingInfo,
    serviceBeingReserved,
  } = reservations;

  const { bundled } = serviceBeingReserved!;

  window.scrollTo(0, 0);
  if (isEditing) {
    if (isSession || canChooseProvider === 'CANT') {
      return dispatch({ type: 'SET_STEP', payload: 'serviceSelection' });
    }
    return dispatch({ type: 'SET_STEP', payload: 'providerSelection' });
  }
  if (isSession) {
    return dispatch({ type: 'SET_STEP', payload: 'serviceSelection' });
  }
  if (bookingMode === 'separate' && canChooseProvider === 'CANT') {
    return dispatch({ type: 'SET_STEP', payload: 'serviceSelection' });
  }
  if (
    bookingMode === 'consecutive' ||
    isSalfa ||
    (servicesToReserve.length === 1 && (canChooseProvider === 'CANT' || !!providerPreSelected))
  ) {
    if (companyBookingInfo.clientExclusive) {
      return dispatch({ type: 'SET_STEP', payload: 'reservationSummary' });
    }
    return dispatch({ type: 'SET_STEP', payload: 'clientForm' });
  }
  if (!providerPreSelected && !bundled) {
    return dispatch({ type: 'SET_STEP', payload: 'providerSelection' });
  }
  if (bundled) {
    return dispatch({ type: 'SET_STEP', payload: 'serviceSelection' });
  }
  return dispatch({ type: 'SET_STEP', payload: 'serviceSelection' });
};
